<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dark :style="stickyTop" extension-height="4">
			<v-toolbar-title>{{ $t("Financieel dagboek") }} </v-toolbar-title>
			<v-spacer />
			<v-toolbar-items class="kmp">
				<v-autocomplete style="width: auto; max-width: 225px" class="text-overline mx-3 my-auto"
					v-model="fiscalPeriod" :items="sessionStore.fiscalPeriods" placeholder="filter op periode" hide-details
					solo flat dense outlined clearable append-icon="mdi-filter-outline"
					@change="refreshFinancialJournal"></v-autocomplete>
				<v-autocomplete style="width: auto; max-width: 225px" class="text-overline my-auto" v-model="bankId"
					:items="banks" placeholder="filter op bank" hide-details solo flat dense outlined clearable
					append-icon="mdi-filter-outline" @change="refreshFinancialJournal"></v-autocomplete>
			</v-toolbar-items>
			<template #extension v-if="loading">
				<v-progress-linear bottom indeterminate color="white"></v-progress-linear>
			</template>
		</v-toolbar>
		<v-container>
			<v-row v-for="bankStatement of financialJournal" :key="bankStatement.stuknr">
				<v-col cols="12">
					<v-card min-width="600" max-width="960" class="mx-auto">
						<v-card-title class="text-subtitle-2">
							<v-row align="center">
								<v-col cols="1">
									{{ bankStatement.stuknr }}
								</v-col>
								<v-col>
									{{ bankStatement.banknaam }} &nbsp;
									<span>Uittreksel {{ bankStatement.uittrekselnr }}</span>
								</v-col>
								<v-col>{{ $d(new Date(bankStatement.datum), 'short') }}</v-col>
								<v-spacer />
								<v-col align="right">
									BS: {{ $n(bankStatement.beginsaldo, "ccy") }}
								</v-col>
								<v-col align="right">
									ES: {{ $n(bankStatement.eindsaldo, "ccy") + " " + bankStatement.muntcode }}
								</v-col>
							</v-row>
						</v-card-title>
						<v-divider />
						<v-card-text class="grey lighten-5">
							<v-row>
								<v-col cols="12">
									<v-sheet outlined>
										<v-data-table disable-sort disable-pagination hide-default-footer
											:items="bankStatement.booking" :headers="bookingHeaders" dense>
											<template v-slot:[`item.omschrijving`]="{
												item,
											}">
												{{
													item.naam
													? item.naam
														.substring(0, 40)
														.toLowerCase()
													: item.omschrijving
														.substring(0, 40)
														.toLowerCase()
												}}
											</template>
											<template v-slot:[`item.debet`]="{ item }">
												<span v-if="item.debet">{{
													$n(item.debet, "ccy")
												}}</span>
											</template>
											<template v-slot:[`item.credit`]="{ item }">
												<span v-if="item.credit">{{
													$n(item.credit, "ccy")
												}}</span>
											</template>
											<template v-slot:[`item.ref`]="{ item }">
												{{
													item.ref
														.substring(0, 45)
														.toLowerCase()
												}}
											</template>
										</v-data-table>
									</v-sheet>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col></v-row>
			<v-row>
				<v-spacer />
				<v-btn text outlined class="my-3" v-if="this.rowIdent" color="primary" :loading="loading"
					@click="fetchFinancialJournal()">
					meer tonen
				</v-btn>
				<v-spacer />
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import { useSessionStore } from "@/store/session";
export default {
	name: "FinancialJournal",
	computed: {
		bookingHeaders() {
			return [
				{ text: "Periode", value: "periode", width: "75px" },
				{ text: "Reknr", value: "gnr", width: "75px" },
				{
					text: "Omschrijving",
					value: "omschrijving",
					width: "250",
				},
				{ text: "Debet", value: "debet", align: "end", width: "125" },
				{ text: "Credit", value: "credit", align: "end", width: "125" },
				{ text: "Ref", value: "ref" },
			];
		},
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 5,
			};
		},
	},
	data() {
		return {
			bankId: null,
			banks: [],
			fiscalPeriod: 0,
			loading: false,
			financialJournal: [],
			rowNum: 0,
			rowIdent: "",
			rowsToBatch: 25,
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async fetchBanks() {
			const sdoName = "boek/objects/dBank.r";
			let params = {
				action: "read",
				fiscalYear: Number(this.sessionStore.fiscalYear),
				nbrOfRows: 999,
				setOpenQuery: "for each bank no-lock indexed-reposition",
			};
			this.loading = true;
			const response = await caas.sdo(sdoName, params);
			let x = response.data.data.RowObject;
			if (x) {
				this.banks = x.map((v) => {
					return { text: v.naam, value: v.banknr };
				});
			}
			this.loading = false;
		},
		async fetchFinancialJournal() {
			this.loading = true;
			let params = {
				year: Number(this.sessionStore.fiscalYear),
				period: Number(this.fiscalPeriod) || 0,
				bankId: this.bankId,
				rowNum: this.rowNum,
				rowIdent: this.rowIdent,
				rowsToBatch: this.rowsToBatch,
			};
			const response = await caas.rpc("getFinancialJournal", params);
			if (response.data) {
				this.financialJournal.push(
					...response.data.data.financialJournal.statement
				);
				this.rowNum = response.data.rowNum;
				this.rowIdent = response.data.rowIdent;
			} else if (response.data.error) {
				console.log(response.error);
			}
			this.loading = false;
		},
		refreshFinancialJournal() {
			this.financialJournal = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.fetchFinancialJournal();
		},
	},
	mounted() {
		this.fetchBanks();
		this.fetchFinancialJournal();
	},
};
</script>

<style>
</style>